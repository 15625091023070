import React from 'react';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../../utils/Cookie';
import classNames from 'classnames';
import Btn from '../Btn';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesNordr from './CookieBar.nordr.module.scss';
import stylesFolkhem from './CookieBar.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

class CookieBar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hide: props.initialStateIsHidden,
        };

        this.close = this.close.bind(this);
    }

    componentDidMount() {
        if (!getCookie('cookiebar')) {
            this.setState({
                hide: false,
            });
        }
    }

    close() {
        setCookie('cookiebar', 'hide');
        this.setState({
            hide: true,
        });
    }

    render() {
        const { wysiwyg, buttonText } = this.props;

        const theme = this.context;
        const styles = getStyles(theme);

        if (this.state.hide || !buttonText) {
            return null;
        }

        return (
            <div className={styles["CookieBar"]}>
                <div className={styles["CookieBar__Inner"]}>
                    <div
                        className={styles["CookieBar__Text"]}
                        dangerouslySetInnerHTML={{ __html: wysiwyg }}
                    />

                    <div className={styles["CookieBar__Button"]}>
                        <Btn
                            element="span"
                            className={classNames('Btn', {
                                'Btn--brand': theme === themes.FOLKHEM,
                            })}
                            onClick={this.close}>
                            {buttonText}
                        </Btn>
                    </div>
                </div>
            </div>
        );
    }
}

CookieBar.propTypes = {
    wysiwyg: PropTypes.string,
    buttonText: PropTypes.string,
    initialStateIsHidden: PropTypes.bool,
};

CookieBar.defaultProps = {};

CookieBar.contextType = ThemeContext;

export default CookieBar;
